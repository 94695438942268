import React from 'react';
import styled from 'react-emotion';

import { breakpoints, colors, fonts, spacing } from '../../utils/styles';

const ProductListingHeaderRoot = styled(`header`)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 40em;
  padding: ${spacing.lg}px;
  text-align: center;
`;

const Title = styled(`h1`)`
  color: ${colors.brand};
  font-family: ${fonts.heading};
  font-size: 2.4rem;
  letter-spacing: -0.02em;
  line-height: 1;
  margin: 0;
  margin-top: ${spacing.md}px;

  @media (min-width: ${breakpoints.desktop}px) {
    font-size: 3rem;
  }
`;

const Subheading = styled(`h3`)`
  // color: ${colors.brand};
  font-family: ${fonts.heading};
  font-size: 1.5rem;
  line-height: 1;
  margin: 0;
  //opacity: 0.9;
  margin-top: ${spacing.sm}px;
  font-variant: small-caps;
  text-transform: lowercase;

  // @media (min-width: ${breakpoints.desktop}px) {
  //   font-size: 1.5rem;
  // }
`;

const CallOut = styled(`h5`)`
  color: ${colors.accent};
  background-color: ${colors.brand};
  padding: 0.75rem;
  font-family: ${fonts.heading};
  font-size: 1.25rem;
  line-height: 1;
  margin: 0;
  //opacity: 0.9;
  margin-top: ${spacing['2xl']}px;

  @media (min-width: ${breakpoints.desktop}px) {
    font-size: 1.5rem;
  }
`;

const Intro = styled(`p`)`
  color: ${colors.text};
  font-size: 1rem;
  line-height: 1.4;
  margin: 0;
  margin-top: ${spacing.xl}px;

  @media (min-width: ${breakpoints.desktop}px) {
    font-size: 1.1rem;
    line-height: 1.6;
  }
`;

const ProductListingHeader = () => (
  <ProductListingHeaderRoot>
    <Title>Second Chance Church Merch</Title>
    <Subheading>In Collaboration With The Growth Company</Subheading>
  </ProductListingHeaderRoot>
);

export default ProductListingHeader;
