import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'react-emotion';

import ProductListingHeader from './ProductListingHeader';
import ProductListingItem from './ProductListingItem';

import { breakpoints, spacing } from '../../utils/styles';
import Heading from '../Layout/Heading';

const ProductListingContainer = styled(`div`)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacing.lg}px;

  @media (min-width: ${breakpoints.desktop}px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: ${spacing['2xl']}px;
  }
`;

const checkForAvailableForSale = (products) => {
    let newProducts = []

    if (products && products.length) {
        products.map((product) => {
            if (product.variants) {
                let variantAdded = false
                product.variants.map((variant) => {
                    if (variant.availableForSale && !variantAdded) {
                        variantAdded = true
                        newProducts.push(product)
                    }
                })
            }
        })
    }

    return newProducts
}

const ProductListing = () => (
  <StaticQuery
    query={graphql`
      query ProductListingQuery {
        newProducts: allShopifyProduct(filter: { publishedAt: { gte: "2019-08-01" }}) {
          totalCount
          products: nodes {
            id
            handle
            title
            description
            productType
            variants {
              shopifyId
              title
              price
              availableForSale
            }
            images {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 910, maxHeight: 910) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        apparel: shopifyCollection(handle: { eq: "apparel"}) {
          products {
            id
            handle
            title
            description
            productType
            variants {
              shopifyId
              title
              price
              availableForSale
            }
            images {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 910, maxHeight: 910) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        books: shopifyCollection(handle: { eq: "books"}) {
          products {
            id
            handle
            title
            description
            productType
            variants {
              shopifyId
              title
              price
              availableForSale
            }
            images {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 910, maxHeight: 910) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ newProducts, apparel, books }) => {
        const newProductsInStock = checkForAvailableForSale(newProducts.products)
        const apparelInStock = checkForAvailableForSale(apparel.products)
        const booksInStock = checkForAvailableForSale(books.products)

        return (
            <>
                <ProductListingHeader/>
                {(newProductsInStock && newProductsInStock.length > 0) &&
                    <>
                        <Heading heading={'Newly Added'}/>
                        <ProductListingContainer>
                            {newProductsInStock.map((product) => (
                                <ProductListingItem key={product.id} product={product}/>
                            ))}
                        </ProductListingContainer>
                    </>
                }
                {(apparelInStock && apparelInStock.length > 0) &&
                    <>
                        <Heading heading={'Apparel'}/>
                        <ProductListingContainer>
                            {apparelInStock.map((product) => (
                                <ProductListingItem key={product.id} product={product}/>
                            ))}
                        </ProductListingContainer>
                    </>
                }

                {(booksInStock && booksInStock.length > 0) &&
                    <>
                        <Heading heading={'Books'}/>
                        <ProductListingContainer>
                            {booksInStock.map((product) => (
                                <ProductListingItem key={product.id} product={product}/>
                            ))}
                        </ProductListingContainer>
                    </>
                }

            </>
        )
    }}
  />
);

export default ProductListing;
